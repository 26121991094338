export default function IconAdd(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 10"
      id="Add-Circle--Streamline-Micro"
      width="100%"
      height="100%"
    >
      <desc>{'Add Circle Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 9.5a4.5 4.5 0 1 0 0 -9 4.5 4.5 0 0 0 0 9Z"
        strokeWidth={1}
      />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5 3v4" strokeWidth={1} />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M3 5h4" strokeWidth={1} />
    </svg>
  );
}
